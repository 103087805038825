import { format } from 'date-fns'
import { HttpService } from 'http/httpService'
import { InventoryItem } from 'inventory/inventoryService'
import { Availability } from 'reservations/types'
import { convertToSnakeCase } from 'utils/camelToSnake'
import { OpeningTimesListItem } from 'venue/openingTimes/openingTimesService'

export type GetCapacityReportQueryParams = {
    date: string
    resourceIds?: string
    locationId?: string
}

export type GetCapacityReportResponseData = {
    timezone: string
    openingTime: OpeningTimesListItem
    resources: InventoryItem[]
    availability: Availability[]
}

export class CapacityReportService {
    constructor(private httpService: HttpService, private endpoint: string) {}

    async getCapacityReport({
        accountSlug,
        date,
        ...params
    }: { accountSlug: string } & GetCapacityReportQueryParams): Promise<GetCapacityReportResponseData> {
        const queryParams = new URLSearchParams({
            available_date: format(date, 'yyyy-MM-dd'),
            ...convertToSnakeCase(params),
        })

        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/accounts/${accountSlug}/capacity_report/?${queryParams.toString()}`,
            {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                },
            },
        )

        return await response.json()
    }
}
