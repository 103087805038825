import { HttpService } from 'http/httpService'
import { removeEmptyStrings, trimStrings } from 'utils/requestPayload'
import { MarketType } from '../accountInfo'

export type RefundFeeType = 'absolute' | 'percentage'

export type CommissionType =
    | 'fixed'
    | 'ticket_and_upsell'
    | 'inc_after_yearly_target'
    | 'inc_after_monthly_target'
    | 'brackets'

export type PricingStrategy = 'opt_revenue' | 'opt_avg_ticket_price' | 'opt_visitor_dist'

export interface FixedCommissionType {
    fixedCommission: number
}

export interface TicketAndUpsellCommissionType {
    ticketCommission: number
    upsellCommission: number
}
export interface YearlyTargetCommissionType {
    initialCommission: number
    afterTargetCommission: number
    target: number
}
export interface MonthlyTargetType {
    month: number
    target: number
}
export interface MonthlyTargetCommissionType {
    initialCommission: number
    afterTargetCommission: number
    monthlyTargets: MonthlyTargetType[]
}
export interface BracketType {
    untilTicketsSold: number
    commission: number
}
export interface BracketCommissionType {
    brackets: BracketType[]
    finalCommission: number
}

export type CommissionInputType =
    | FixedCommissionType
    | TicketAndUpsellCommissionType
    | YearlyTargetCommissionType
    | MonthlyTargetCommissionType
    | BracketCommissionType

export type PeriodType = 'monthly' | 'yearly'

interface ContractItems {
    contractFrom: string | null
    contractTo: string | null
    transactionFee: number | null
    licenseFee: number | null
    licenseFeePeriod: PeriodType
    startupFee: number | null
    marketingFee: number | null
    marketingFeePeriod: PeriodType
    codeReaderFee: number | null
    codeReaderUsers: number | null
    commissionType: CommissionType
    commission: CommissionInputType
}

interface ContractItemsWithUpdateInfo extends ContractItems {
    updatedBy: string | null
    updatedAt: string | null
}

interface AccountItems {
    enabled: boolean
    vatNumber: string | null
    legalBusinessName: string | null
    registrationNumber: string | null
    phone: string | null
    emailAddress: string | null
    streetAddress: string | null
    city: string | null
    postalCode: string | null
    complement: string | null
    marketType: MarketType
    partnerRefundFee: string | null
    partnerRefundFeeType: RefundFeeType | null
    paymentLinkDurationInHours: number | null
    bankTransferDurationInHours: number | null
}

interface AccountItemsForMutation extends Omit<AccountItems, 'marketType'> {
    companyName: string | null
    url: string | null
    industry: string | null
    country: string | null
}

interface AccountItemsWithUpdateInfo extends Omit<AccountItems, 'marketType'> {
    updatedBy: string | null
    updatedAt: string | null
}

interface updateAccountDetailsError {
    code: string
    errors: {
        [key: string]: string[] | null
    }
}

interface updateAccountDetailsResult {
    success: boolean
    error: updateAccountDetailsError | null
}

export class ManagementService {
    constructor(
        private httpService: HttpService,
        private backofficeEndpoint: string,
        private dashboardApiEndpoint: string,
    ) {}

    async getAccountDetails(accountSlug: string): Promise<AccountItemsWithUpdateInfo> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/settings/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    async getContractDetails(accountSlug: string): Promise<ContractItemsWithUpdateInfo> {
        const query = `query Contract($accountSlug: ID) {
      account(slug: $accountSlug) {
        contract {
          contractFrom,
          contractTo,
          licenseFee,
          licenseFeePeriod,
          startupFee,
          refundFee,
          refundFeeType,
          marketingFee,
          marketingFeePeriod,
          codeReaderFee,
          codeReaderUsers,
          transactionFee,
          commissionType,
          commission {
            ... on FixedCommissionType {
                  fixedCommission
            }
            ... on TicketAndUpsellCommissionType {
                ticketCommission
                upsellCommission
            }
            ... on YearlyTargetCommissionType {
                initialCommission
                afterTargetCommission
                target
            }
            ... on MonthlyTargetCommissionType {
                initialCommission
                afterTargetCommission
                monthlyTargets {
                    month
                    target
                }
            }
            ... on BracketCommissionType {
                finalCommission
                brackets {
                    untilTicketsSold
                    commission
                }
            }
          }
          updatedBy
          updatedAt
        }
      }
    }`

        const body = JSON.stringify({
            query,
            variables: { accountSlug },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.account.contract
    }

    async updateAccountDetails(
        accountSlug: string,
        accountDetails: AccountItemsForMutation,
    ): Promise<updateAccountDetailsResult> {
        const body = removeEmptyStrings(trimStrings(accountDetails))
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/settings/`,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        const payload = await response.json()
        if (payload.error) {
            return {
                success: false,
                error: payload.error,
            }
        }
        return {
            success: true,
            error: null,
        }
    }

    async updateContractDetails(accountSlug: string, contractItems: ContractItems): Promise<void> {
        const query = `
      mutation UpdateContractDetails (
        $accountSlug: String!,
        $contractFrom: Date,
        $contractTo: Date,
        $licenseFee: Float,
        $licenseFeePeriod: PeriodType,
        $startupFee: Float,
        $marketingFee: Float,
        $marketingFeePeriod: PeriodType,
        $codeReaderFee: Float,
        $codeReaderUsers: Int,
        $commissionType: CommissionType,
        $commission: CommissionInputType,
        $transactionFee: Float,
      ) {
        updateAccountContractDetails(
          accountSlug: $accountSlug,
          contractFrom: $contractFrom,
          contractTo: $contractTo,
          transactionFee: $transactionFee,
          licenseFee: $licenseFee,
          licenseFeePeriod: $licenseFeePeriod,
          startupFee: $startupFee,
          marketingFee: $marketingFee,
          marketingFeePeriod: $marketingFeePeriod,
          codeReaderFee: $codeReaderFee,
          codeReaderUsers: $codeReaderUsers,
          commissionType: $commissionType,
          commission: $commission,
        ) {
          ok,
          errorCode
        }
      },
    `

        const body = JSON.stringify({
            query,
            variables: { accountSlug, ...contractItems },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (!payload.data.updateAccountContractDetails.ok) {
            throw new Error('Oops, something went wrong')
        }
    }

    scheduleContractEmails = async (checkout: string, refDate: string): Promise<void> => {
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/scheduled_emails/`, {
            method: 'POST',
            body: JSON.stringify({
                emailType: 'contract_emails',
                checkout,
                refDate,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status !== 200) {
            throw new Error('Oops! Something went wrong, please try again.')
        }
    }

    async getPricingStrategy(accountSlug: string): Promise<PricingStrategy> {
        const query = `query PricingStrategy($accountSlug: ID) {
      account(slug: $accountSlug) {
        contract {
          pricingGoal
        }
      }
    }`

        const requestBody = JSON.stringify({
            query: query,
            operationName: 'PricingStrategy',
            variables: { accountSlug: accountSlug },
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (response.status !== 200) {
            const reason = await response.text()
            throw new Error(`Oops! Something went wrong, please try again: ${reason}`)
        }

        const body = await response.json()
        return body.data.account.contract.pricingGoal
    }
}
