/**
 * Converts a data URL to a File object.
 *
 * @param dataURI - The data URL to convert.
 * @param fileName - The name of the resulting file. Defaults to a timestamped name.
 * @returns The resulting File object, or null if the data URL is invalid.
 */
export function dataURLtoFile(
    dataURI?: string | null,
    fileName: string = `ImageCapture_${new Date().toISOString()}.jpeg`,
): File | null {
    if (!dataURI) return null

    // Split the data URL into the metadata and the data parts
    const [metadata, data] = dataURI.split(',')

    // Decode the data part
    const byteString = metadata.includes('base64') ? atob(data) : decodeURI(data)

    // Extract the MIME type from the metadata part
    const mimeString = metadata.split(':')[1].split(';')[0]

    // Convert the decoded data to a byte array
    const byteArray = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i)
    }

    // Create a Blob from the byte array and MIME type
    const imageBlob = new Blob([byteArray], { type: mimeString })

    // Create and return a File object from the Blob
    return new File([imageBlob], fileName, { type: imageBlob.type })
}
