import { AuthenticatedHttpService } from 'http/httpService'
import { MarketType } from 'settings/accountSettings/accountInfo'

export interface AccountCreateRequest {
    name: string
    websiteUrl: string
    adyenSettings: {
        accountName: string
        countryCode: string
    }
    emailSettings: {
        supportAgentName: string
        supportEmail: string
    }
    marketType: MarketType
}

export type AccountInfo = AccountCreateRequest

export class AccountService {
    constructor(private httpService: AuthenticatedHttpService, public backofficeEndpoint: string) {}

    private accountUrl(accountSlug: string) {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/`
    }

    async getAccount(accountSlug: string): Promise<AccountInfo | null> {
        const response = await this.httpService.fetch(this.accountUrl(accountSlug))
        if (response.status === 404) {
            return null
        }
        if (!response.ok) {
            throw new Error('Unable to create account')
        }
        return await response.json()
    }

    async createAccount(accountSlug: string, data: AccountCreateRequest): Promise<void> {
        const response = await this.httpService.fetch(this.accountUrl(accountSlug), {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error('Unable to create account')
        }
    }
}
