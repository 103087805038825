import { HttpService } from 'http/httpService'
import { Result, success, failure } from 'result'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

interface ProductList {
    id: string
    name: string
}

interface Article {
    id: string
    uuid: string
    name: string
}

export type TimeSlotGroupStatus = 'active' | 'upcoming' | 'expired'

export interface TimeSlotGroupPayload {
    id: string
    name: string
    productsLists: ProductList[]
    article?: Article | null
    weekdays: number[]
    availableFrom: string | null
    availableTo: string | null
    timeSlots: TimeSlot[]
    priority: number | null
    visibilityOffsetMinutes: number | null
}

export interface TimeSlotGroup extends TimeSlotGroupPayload {
    productsListNames: string
    status: TimeSlotGroupStatus
}

export interface TimeSlot {
    id?: string
    startTime: string
    endTime: string | null
}

export interface TimeSlotsValidationError {
    code: 'timeslot_groups_conflict' | 'unknown_validation_error'
    message: string
}

export class TimeSlotsService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async listTimeSlots(accountSlug: string, forArticles: boolean): Promise<TimeSlotGroup[]> {
        const query = forArticles ? '?assigned_to=articles' : ''
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/timeslot_groups/${query}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch time slots')
        }
        return await response.json()
    }

    async getTimeSlot(accountSlug: string, id: string): Promise<TimeSlotGroup> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/timeslot_groups/${id}/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch time slot')
        }
        return await response.json()
    }

    async createTimeSlotGroup(
        accountSlug: string,
        data: TimeSlotGroupPayload,
    ): Promise<Result<TimeSlotGroup, TimeSlotsValidationError>> {
        const logEventType: EventType = 'timeslot_group_created'
        const logEventData: ActionEventData = {
            category: 'time_slots',
            payload: data,
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/timeslot_groups/`,
            {
                method: 'POST',
                body: JSON.stringify({
                    ...data,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            const body = await response.json()
            this.loggingService.logError(body, logEventType, logEventData)
            if (body.error) {
                return failure(body.error)
            }
        }

        response = await this.httpService.fetch(response.headers.get('Location')!)
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to retrieve timeslot group')
        }

        const resBody = await response.json()
        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: resBody.id },
        })
        return success(resBody)
    }

    async updateTimeSlotGroup(
        accountSlug: string,
        data: TimeSlotGroupPayload,
        id: string,
    ): Promise<Result<TimeSlotGroup, TimeSlotsValidationError>> {
        const logEventType: EventType = 'timeslot_group_updated'
        const logEventData: ActionEventData = {
            category: 'time_slots',
            payload: { ...data, id },
        }
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/timeslot_groups/${id}/`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    ...data,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            const body = await response.json()
            this.loggingService.logError(body, logEventType, logEventData)
            if (body.error) {
                return failure(body.error)
            }
        }

        this.loggingService.logAction(logEventType, logEventData)
        return success(await response.json())
    }

    async updateTimeSlotGroupPriority(
        accountSlug: string,
        priority: number,
        id: string,
    ): Promise<Result<TimeSlotGroup, TimeSlotsValidationError>> {
        const logEventType: EventType = 'timeslot_group_updated'
        const logEventData: ActionEventData = {
            category: 'time_slots',
            payload: { priority, id },
        }
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/timeslot_groups/${id}/`,
            {
                method: 'PATCH',
                body: JSON.stringify({ priority }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            const body = await response.json()
            this.loggingService.logError(body, logEventType, logEventData)
            if (body.error) {
                return failure(body.error)
            }
        }

        this.loggingService.logAction(logEventType, logEventData)
        return success(await response.json())
    }

    async deleteTimeSlotGroup(accountSlug: string, id: string): Promise<void> {
        const logEventType: EventType = 'timeslot_group_deleted'
        const logEventData: ActionEventData = {
            category: 'time_slots',
            payload: { id },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/timeslot_groups/${id}/`,
            {
                method: 'DELETE',
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete time slots')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }
}
