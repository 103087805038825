import React, { Ref, useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { dataURLtoFile } from 'utils/file'
import { Alert, AlertTitle, Button, ToggleButton } from '@mui/material'
import { Cropper, ReactCropperElement } from 'react-cropper'
import 'uiComponents/input/cameraInput/cameraInput.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { ArrowRotateLeftSolid, CheckSolid } from '@convious/icons/src'
import classNames from 'classnames'
import { SingleSelect } from 'uiComponents/input/singleSelect'
import { isLocalStorageAvailable } from 'utils/storage'

type Props = {
    onChange: (capturedPhoto: File | null) => void
    videoConstraints?: Exclude<MediaStreamConstraints['video'], boolean>
}

const USE_CAMERA_MIRRORING_KEY = 'useCameraMirroring'

export const CameraInput = ({
    onChange,
    videoConstraints = {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        facingMode: 'environment',
    },
}: Props) => {
    const webcamRef: Ref<Webcam> = useRef(null)
    const [mediaError, setMediaError] = useState<DOMException | null>(null)
    const [mediaLoaded, setMediaLoaded] = useState<boolean>(false)
    const [image, setImage] = useState<string | null | undefined>(null)
    const [mirrorCamera, setMirrorCamera] = useState<boolean | null>(null)
    const cropperRef = useRef<ReactCropperElement>(null)

    // const [deviceId, setDeviceId] = useState<string | null>()
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
    const [deviceId, setDeviceId] = useState<string | undefined>(undefined)

    const handleDevices = useCallback(
        (mediaDevices: MediaDeviceInfo[]) => {
            const videoDevices = mediaDevices.filter(({ kind }) => kind === 'videoinput')
            setDevices(videoDevices)
            if (videoDevices.length) {
                setDeviceId(videoDevices[0].deviceId)
            }
        },
        [setDevices],
    )

    useEffect(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then(handleDevices)
            .catch((error: DOMException) => {
                setMediaError(error)
                setDevices([])
            })
    }, [handleDevices])

    useEffect(() => {
        if (isLocalStorageAvailable()) {
            if (localStorage.getItem(USE_CAMERA_MIRRORING_KEY) === null) {
                localStorage.setItem(USE_CAMERA_MIRRORING_KEY, 'false')
            } else {
                setMirrorCamera(localStorage.getItem(USE_CAMERA_MIRRORING_KEY) === 'true')
            }
        } else {
            setMirrorCamera(false)
        }
    }, [])

    useEffect(() => {
        if (isLocalStorageAvailable()) {
            localStorage.setItem(USE_CAMERA_MIRRORING_KEY, JSON.stringify(mirrorCamera))
        }
    }, [mirrorCamera])

    const onCrop = () => {
        if (typeof cropperRef.current?.cropper !== 'undefined') {
            const dataUrl = cropperRef.current?.cropper.getCroppedCanvas().toDataURL('image/jpeg')
            setImage(null)
            onChange(dataURLtoFile(dataUrl))
        }
    }

    const onUserMediaError = (error: DOMException) => {
        console.error(error.name, error.message, error)
        setMediaError(error)
    }
    const onUserMedia = () => {
        setMediaError(null)
        setMediaLoaded(true)
    }

    const retryLoadingCamera = () => {
        setMediaError(null)
        setMediaLoaded(false)
        setImage(null)
    }

    const handleCapture = useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot()
        setImage(imageSrc)
    }, [webcamRef])

    const ASPECT_RATIO: number = 7 / 9

    return (
        <div className="camera-capture-input">
            {mediaError && (
                <div className="section">
                    <Alert
                        severity="error"
                        action={
                            <Button variant="contained" color="error" onClick={retryLoadingCamera}>
                                Retry
                            </Button>
                        }
                    >
                        <AlertTitle>Your browser denied camera permission, or a camera could not be found.</AlertTitle>
                        <details>
                            <summary>
                                To fix this:
                                <ul>
                                    <li>Double check if there’s a camera connected to this device.</li>
                                    <li>Make sure camera access is granted for this website.</li>
                                    <li>Click &quot;Retry&quot; button</li>
                                </ul>
                            </summary>
                            <div>
                                <strong>Error details:</strong> [{mediaError.name}] {mediaError.message}
                            </div>
                        </details>
                    </Alert>
                </div>
            )}
            {image && (
                <div className="section">
                    <Cropper
                        src={image}
                        style={{ height: 360, width: 640 }}
                        // @ts-ignore
                        aspectRatio={ASPECT_RATIO}
                        initialAspectRatio={ASPECT_RATIO}
                        guides={false}
                        ref={cropperRef}
                        minCropBoxWidth={50}
                        zoomable={false}
                        viewMode={1}
                    />
                    <div className="controls">
                        <Button variant="contained" size="large" startIcon={<CheckSolid />} onClick={onCrop}>
                            Use photo
                        </Button>
                        <Button
                            variant="outlined"
                            size="large"
                            color="error"
                            startIcon={<ArrowRotateLeftSolid />}
                            onClick={() => setImage(null)}
                        >
                            Retake photo
                        </Button>
                    </div>
                </div>
            )}
            {!image && !mediaError && (
                <div className="section">
                    <div className="top-controls">
                        {devices.length === 0 && (
                            <Alert severity="info">
                                No camera devices found. Please connect a camera and try again.
                            </Alert>
                        )}
                        {devices.length && (
                            <SingleSelect
                                options={devices.map(({ deviceId, label }, index) => ({
                                    value: deviceId,
                                    name: label || `Device ${index + 1}`,
                                }))}
                                selected={deviceId ?? null}
                                onSelect={(value: string) => setDeviceId(value)}
                            />
                        )}
                        {!mediaLoaded && <Alert severity="info">Loading camera...</Alert>}
                    </div>
                    <Webcam
                        className={classNames({ mirrored: mirrorCamera })}
                        audio={false}
                        height={360}
                        width={640}
                        ref={webcamRef}
                        onUserMedia={onUserMedia}
                        onUserMediaError={onUserMediaError}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{ ...videoConstraints, deviceId }}
                        disablePictureInPicture={true}
                    />
                    {mediaLoaded && (
                        <div className="controls">
                            <Button
                                size="large"
                                variant="contained"
                                onClick={handleCapture}
                                startIcon={<FontAwesomeIcon icon={faCamera} width={24} height={24} />}
                            >
                                Capture
                            </Button>
                            <ToggleButton
                                size="large"
                                color="primary"
                                value={true}
                                selected={mirrorCamera ?? false}
                                onChange={() => setMirrorCamera((prevValue: boolean) => !prevValue)}
                            >
                                <FontAwesomeIcon icon={faExchangeAlt} width={24} height={24} />
                                Mirror camera
                            </ToggleButton>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
